import i18n from "./translation/i18n";

export const updateSysLang = (lang, setStateProps) => {
    if (lang && ['az', 'en', 'ru'].includes(lang)) {
        i18n.changeLanguage(lang);
        localStorage.eSchoolLang = lang;
        setStateProps('systemLanguage', lang);
    } else {
        return false;
    }
};