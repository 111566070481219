import React, { Component } from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as commonActions from "../redux/actions/Common";
import * as userActions from "../redux/actions/User";

import PublicView from "../components/pages/PublicView";
import PublicPages from "../components/pages/PublicPages";

class Public extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: ""
        };
    }

    componentDidMount() {
    }

    handleChange = (e) => {
        e.target.value && e.target.setCustomValidity("");
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        this.props.loading("login", true);
        this.props.login(username, password);
    };

    render() {
        return (
            <Switch>
                <Route exact path="/" render={() => {
                    return (
                        <Redirect to={`/${localStorage.eSchoolLang}/`} />
                    )
                }} />
                <Route exact path="/:lang/" component={PublicView} />
                <Route path="/:lang/:page" component={PublicPages} />
            </Switch>
        );
    }
}

export default withRouter(
    connect(({ ...state }) => ({ state }), {
        ...commonActions,
        ...userActions,
    })(Public)
);
