const translationEn = {
    "library": "Library",
    "examinations": "Examinations",
    "contactUs": "Contact us",
    "languageChoise": "Language choice",
    "joinUs": "Join us",
    "exit": "Exit",
    "siteSlogan": "Learn and manage your platform. It is never too late to learn.",
    "username": "Username",
    "saa": "Name, surname and father name",
    "name": "Name",
    "surname": "Surname",
    "fatherName": "Father name",
    "pin": "PIN",
    "email": "Email",
    "phoneNumber": "Phone number",
    "passwordUpdate": "Password update",
    "password": "Password",
    "passwordRepeat": "Repeat password",
    "updatePassword": "Update password",
    "edit": "Edit",
    "delete": "Delete",
    "save": "Save",
    "add": "Add",
    "search": "Search",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "change": "Change",
    "chooseDD": "Choose...",
    "note": "Note",
    "login": "Sign in",
    "yourMessage": "Your message",
    "send": "Send",
    "readMore": "Read more",
    "moduleExam": "Exam module",
    "moduleJournal": "Journal module",
    "moduleLibrary": "Library module",
    "moduleSchedule": "Lesson schedule module",
    "moduleExamInfo": "Here, teachers can create questions for students and prepare tests for them. Students, in turn, can use these tests to test their knowledge. These tests can also be used to test students' knowledge.",
    "moduleJournalInfo": "Teachers can keep notes about students in an electronic journal. As a result of these assessments, teachers will be able to control both student attendance and points.",
    "moduleLibraryInfo": "This module can be used by both students and the school-appointed librarian. Thus, with the help of the module, the librarian can register and monitor the books under his control. Students can read the list of books here and choose the book they want to read. In this module, students can also apply to the librarian online for the registration of book.",
    "moduleScheduleInfo": "Students and teachers will be introduced to their schedules after the school administration divides the classes into classes. So, teachers will be able to watch the classes they will teach on weekdays and the hours of that lesson here. Students will also be able to view class schedules here.",
    "error404": "ERROR 404 - NOT FOUND",
    "pgNotFound": "Page not found.",
    "mainPage": "MAIN PAGE",
    //validations
    "lenMinFive": "The password must consist of at least 5 cells!",
    "enterPassword": "Enter the password!",
    "enterYourPassword": "Enter your password!",
    "enterRepeat": "Re-enter the password!",
    "nonEqualPassword": "Passwords do not repeat each other!",
    "enterUsername": "Enter the username!",
    "enterName": "Enter the name!",
    "enterSurname": "Enter the surname!",
    "enterFatherName": "Enter the father name!",
    "enterCompanyName": "Enter the name of the company!",
    "enterPin": "Enter the pin!",
    "pinLenMinSeven": "The pin code must consist of 7 digits!",
    "enterPhone": "Enter the phone number!",
    "phoneLenMinSeven": "Phone number must consist of 7 digits!",
    "enterEmail": "Enter the email!",
    "incorrectEmailContent": "The content of the e-mail address is incorrect",
    "enterFormName": "Enter the name of the form!",
    "enterLabelText": "Text entry is required for the label component.",
    "enterServiceLink": "Enter the service link!",
    "enterTabName": "Enter the tab name!",
    "enterSrGroupName": "Enter the service group name!",
    "enterSrName": "Enter the service name!"
}
export default translationEn;