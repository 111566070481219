import React, { Component } from 'react';
import { connect } from "react-redux";
import * as Common from "../../redux/actions/Common";
import { withRouter } from "react-router-dom";

import { updateSysLang } from '../../utils/helper';

import ContactUs from '../partials/ContactUs';
import Examination from '../partials/Examination';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Journal from '../partials/Journal';
import Library from '../partials/Library';
import Login from '../partials/Login';
import Schedule from '../partials/Schedule';
import Error from '../pages/Error';

class PublicView extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    componentDidMount() {
        console.log(this.props);
        updateSysLang(this.props.match.params.lang, this.setStateProps);
    }
    setStateProps = (key, data) => {
        this.props.setState(key, data);
    }
    render() {
        return (
            ['az', 'en', 'ru'].includes(this.props.match.params.lang) ? <React.Fragment>
                <div className="top_container">
                    <Header />
                    <Login />
                    <div className="row tw-w-full m-0">
                        <div className="col-md-12 tw-h-10rem">
                            <img src={require('../../assets/img/bus.png').default} alt="" className="img-fluid vehicle_img-box anime_bus-drive"></img>
                        </div>
                    </div>
                </div>
                <div className="common_style">
                    <Library />
                    <Journal />
                    <Examination />
                    <Schedule />
                </div>
                <ContactUs />
                <Footer />
            </React.Fragment>
                : <Error />
        )
    }
}
export default withRouter(
    connect(({ ...state }) => ({ state }), {
        ...Common
    })(PublicView)
);
