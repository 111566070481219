import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Common from "../../redux/actions/Common";
import { t } from '../../utils/translation/i18n'
import InputMask from 'react-input-mask';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }
    componentDidMount = async () => {
    }
    handleChange = e => {
        const { value, name } = e.target;
        if (value) {
            if (name === "pin" && value.length < 7) {
                e.target.setCustomValidity(t("pinLenMinSeven"))
            }
            else if (name === "phoneNumber" && (value.length < 9 || value.includes("_"))) {
                e.target.setCustomValidity(t("phoneLenMinSeven"));
            }
            else if (name === "password" && value.length < 5) {
                e.target.setCustomValidity(t("lenMinFive"));
            }
            else if (name === "mail" && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                e.target.setCustomValidity(t("incorrectEmailContent"));
            }
            else {
                e.target.setCustomValidity('')
            }
        }
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };
    render() {
        const { data } = this.state;
        return (
            <section className="contact_section ">

                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-center d-md-block">
                                <h2>
                                    {t("contactUs")}
                                </h2>
                            </div>
                            <form action="">
                                <div className="contact_form-container">
                                    <input type="text" className="form-control mt-0 main-color-placeholder mainInputBgColor" placeholder={t("saa")}></input>
                                    <input type="email" className="form-control mt-0 main-color-placeholder mainInputBgColor" placeholder={t("email")}></input>
                                    <div className="input-group">
                                        <InputMask type='tel'
                                            className='form-control mt-0 main-color-placeholder mainInputBgColor'
                                            mask={`(+\\9\\94 99) 999-99-99`}
                                            name='phoneNumber'
                                            value={data.phoneNumber || ''}
                                            placeholder='(00) 000-00-00'
                                            onChange={e => this.handleChange(e)}
                                            onInvalid={e => e.target.setCustomValidity(!data.phoneNumber ? t("enterPhone") : data.phoneNumber.length < 9 || data.phoneNumber.includes("_") ? t("phoneLenMinSeven") : '')}
                                            required
                                        />
                                    </div>
                                    <textarea rows={3} className="form-control mt-0 main-color-placeholder mainInputBgColor" placeholder={t("yourMessage")}></textarea>
                                </div>
                                <div className="mt-3">
                                    <button type="submit" className="contact_form-container-button">
                                        {t("send")}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="contact_img-box">
                                <img src={require('../../assets/img/students.jpg').default} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withRouter(
    connect(
        ({ ...state }) => ({ state }),
        {
            ...Common
        }
    )(ContactUs)
);