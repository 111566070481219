import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <section className="container-fluid footer_section">
                <p>
                    &copy; 2021 e-school.az
                </p>
            </section>
        )
    }
}
