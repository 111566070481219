import React, { Component } from 'react'
import { t } from '../../utils/translation/i18n'

export default class Login extends Component {
    render() {
        return (
            <section className="hero_section ">
                <div className="hero-container container">
                    <div className="hero_detail-box">
                        <h3>
                            {t("joinUs")},
                        </h3>
                        <h1>
                            e-school.az
                        </h1>
                        <p className="my-2">
                            {t("siteSlogan")}
                        </p>
                        <div className="contact-form pl-0 py-0">
                            <form action="">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-user main-color"></i></span>
                                    </div>
                                    <input type="text" className="form-control mt-0 main-color-placeholder" placeholder={t("username")}></input>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-unlock-alt main-color"></i></span>
                                    </div>
                                    <input type="password" className="form-control mt-0 main-color-placeholder" placeholder={t("password")}></input>
                                </div>
                                <div className="hero_btn-continer">
                                    <button type="submit" className="call_to-btn btn_white-border tw-flex tw-items-center">
                                        <span>
                                            {t("login")}
                                        </span>
                                        <img src={require('../../assets/img/right-arrow.png').default} alt=""></img>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="hero_img-container">
                        <div>
                            <img src={require('../../assets/img/hero.png').default} alt="" className="img-fluid"></img>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
