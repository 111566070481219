import React, { Component } from 'react'
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as commonActions from "../../redux/actions/Common";
import * as userActions from "../../redux/actions/User";

import Header from "../../components/partials/Header";
import Library from "../../components/partials/Library";
import Journal from "../../components/partials/Journal";
import Examination from "../../components/partials/Examination";
import Schedule from "../../components/partials/Schedule";
import ContactUs from "../../components/partials/ContactUs";
import Footer from "../../components/partials/Footer";
import Login from "../../components/partials/Login";
import Error from "./Error";
import { updateSysLang } from "../../utils/helper";

class PublicPages extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: ""
        };
    }

    componentDidMount() {
        updateSysLang(this.props.match.params.lang, this.setStateProps)
    }

    setStateProps = (key, data) => {
        this.props.setState(key, data);
    }

    render() {
        let parPage = this.props.match.params.page;
        let parLang = this.props.match.params.lang;
        const page = () => {
            switch (parPage) {
                case "library": return <Library moreInfo={true} />;
                case "journal": return <Journal moreInfo={true} />;
                case "examination": return <Examination moreInfo={true} />;
                case "schedule": return <Schedule moreInfo={true} />;
                case "contactUs": return <ContactUs />;
                case "login": return <Login />;

                default: return "error"
            }
        }
        const currPage = page();
        return (
            currPage === "error" || !["en", "az", "ru"].includes(parLang) ?
                <Error />
                : <div className="d-flex flex-column tw-h-full">
                    <Header bgColor="headerMainBgColor" textColor="headerMainColor" mainCSS="headerMainCSS" />
                    <div className="tw-flex-1">
                        {currPage}
                    </div>
                    <Footer />
                </div>
        )
    }
}

export default withRouter(
    connect(({ ...state }) => ({ state }), {
        ...commonActions,
        ...userActions,
    })(PublicPages)
);
