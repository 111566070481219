import React, { Component } from 'react'
import { t } from '../../utils/translation/i18n';

export default class Examination extends Component {
    render() {
        return (
            <div className="common_style">
                <section className="why_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="why_img-container">
                                    <img src={require('../../assets/img/why.png').default} alt=""></img>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="why_detail-box">
                                    <h3>
                                        {t("moduleExam")}
                                    </h3>
                                    <p>{t("moduleExamInfo")}</p>
                                    <div className={`${this.props.moreInfo && 'tw-hidden'}`}>
                                        <a href={`/${localStorage.eSchoolLang}/examination`} className="call_to-btn btn_white-border">
                                            {t("readMore")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
