import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import { me } from "../redux/actions/User";


//containers
import Main from "./Main"

import Public from "./Public";
import Startup from "./Startup";


Modal.setAppElement("#root");

class App extends React.Component {

    render() {
        const { user, common } = this.props.state;
        if (!localStorage.eSchoolLang) {
            localStorage.eSchoolLang = "az"
        }
        return (
            <React.Fragment>
                {user?.login ? (common.startup ?
                    <Startup /> :
                    <div className="tw-flex tw-flex-col tw-h-full">
                        <div className="tw-flex tw-flex-1 tw-bg-gray-300">
                            <Main />
                        </div>
                    </div>)
                    : <Public />
                }
            </React.Fragment>
        );
    }
}

export default withRouter(
    connect(({ ...state }) => ({ state }), { me })(App)
);
