const translationRu = {
    "library": "Библиотека",
    "examinations": "Экзамены",
    "contactUs": "Связаться с нами",
    "languageChoise": "Выбор языка",
    "joinUs": "Присоединяйтесь к нам",
    "exit": "Выход",
    "siteSlogan": "Изучите свою платформу и управляйте ею. Век живи, век учись.",
    "username": "Имя пользователя",
    "saa": "Имя, фамилия и имя отца",
    "name": "Имя",
    "surname": "Фамилия",
    "fatherName": "Имя Отца",
    "pin": "ШТЫРЬ",
    "email": "Электронная почта",
    "phoneNumber": "Номер телефона",
    "passwordUpdate": "Обновление пароля",
    "password": "Пароль",
    "passwordRepeat": "Повторите пароль",
    "updatePassword": "Обновить пароль",
    "edit": "Редактировать",
    "delete": "Удалить",
    "save": "Сохранить",
    "add": "Добавлять",
    "search": "Поиск",
    "confirm": "Подтверждать",
    "cancel": "Отмена",
    "change": "Изменять",
    "chooseDD": "Выбирать...",
    "note": "Примечание",
    "login": "Войти",
    "yourMessage": "Твое сообщение",
    "send": "Отправить",
    "readMore": "Подробнее",
    "moduleExam": "Экзаменационный модуль",
    "moduleJournal": "Модуль журнала",
    "moduleLibrary": "Библиотечный модуль",
    "moduleSchedule": "Модуль расписания уроков",
    "moduleExamInfo": "Здесь учителя могут создавать вопросы для учеников и готовить для них тесты. Студенты, в свою очередь, могут использовать эти тесты для проверки своих знаний. Эти тесты также можно использовать для проверки знаний учащихся.",
    "moduleJournalInfo": "Учителя могут вести записи об учениках в электронном журнале. В результате этих оценок учителя смогут контролировать посещаемость и количество баллов учащихся.",
    "moduleLibraryInfo": "Этот модуль может использоваться как учениками, так и библиотекарем, назначенным школой. Таким образом, с помощью модуля библиотекарь может регистрировать и контролировать книги, находящиеся под его контролем. Студенты могут прочитать здесь список книг и выбрать книгу, которую они хотят прочитать. В этом модуле студенты также могут обратиться к библиотекарю онлайн для регистрации книги.",
    "moduleScheduleInfo": "Учащиеся и учителя будут ознакомлены со своим расписанием после того, как администрация школы разделит классы на классы. Таким образом, учителя смогут посмотреть уроки, которые они будут вести в будние дни, и часы этого урока здесь. Студенты также смогут просматривать расписание занятий здесь.",
    "error404": "ОШИБКА 404 НЕ НАЙДЕНО",
    "pgNotFound": "Страница не найдена.",
    "mainPage": "ГЛАВНАЯ СТРАНИЦА",
    //validations
    "lenMinFive": "Пароль должен состоять не менее чем из 5 ячеек!",
    "enterPassword": "Введите пароль!",
    "enterYourPassword": "Введите пароль!",
    "enterRepeat": "Введите пароль еще раз!",
    "nonEqualPassword": "Пароли не повторяют друг друга!",
    "enterUsername": "Введите имя пользователя!",
    "enterName": "Введите имя!",
    "enterSurname": "Введите фамилию!",
    "enterFatherName": "Введите имя отца!",
    "enterCompanyName": "Введите название компании!",
    "enterPin": "Введите пин-код!",
    "pinLenMinSeven": "Пин-код должен состоять из 7 цифр!",
    "enterPhone": "Введите номер телефона!",
    "phoneLenMinSeven": "Номер телефона должен состоять из 7 цифр!",
    "enterEmail": "Введите адрес электронной почты!",
    "invalidEmailContent": "Неправильное содержание адреса электронной почты",
    "enterFormName": "Введите имя формы!",
    "enterLabelText": "Для компонента метки требуется ввод текста.",
    "enterServiceLink": "Введите ссылку на сервис!",
    "enterTabName": "Введите название вкладки!",
    "enterSrGroupName": "Введите название сервисной группы!",
    "enterSrName": "Введите имя службы!"
}
export default translationRu;