const translationAz = {
    "library": "Kitabxana",
    "examinations": "Sınaqlar",
    "contactUs": "Bizimlə əlaqə",
    "languageChoise": "Dil seçimi",
    "joinUs": "Bizə qatıl",
    "exit": "Çıxış",
    "siteSlogan": "Həm öyrən, həm də öz platformanı idarə et. Öyrənməyin yaşı yoxdur.",
    "username": "İstifadəçi adı",
    "saa": "Ad, soyad, ata adı",
    "name": "Ad",
    "surname": "Soyad",
    "fatherName": "Ata adı",
    "pin": "FİN",
    "email": "Elektron ünvan",
    "phoneNumber": "Telefon nömrəsi",
    "passwordUpdate": "Şifrə yenilənməsi",
    "password": "Şifrə",
    "passwordRepeat": "Şifrənin təkrarı",
    "updatePassword": "Şifrəni yenilə",
    "edit": "Redaktə et",
    "delete": "Sil",
    "save": "Yadda saxla",
    "add": "Əlavə et",
    "search": "Axtar",
    "confirm": "Təsdiq et",
    "cancel": "Ləğv et",
    "change": "Dəyişdir",
    "chooseDD": "Seçim edin...",
    "note": "Qeyd",
    "login": "Daxil ol",
    "yourMessage": "Müraciətiniz",
    "send": "Göndər",
    "readMore": "Daha ətraflı",
    "moduleExam": "Sınaq modulu",
    "moduleJournal": "Qiymətləndirmə modulu",
    "moduleLibrary": "Kitabxana modulu",
    "moduleSchedule": "Dərs cədvəli modulu",
    "moduleExamInfo": "Burada müəllimlər şagirdlər üçün suallar tərtib edərək, onlar üçün sınaqlar hazırlaya bilər. Şagirdlər də öz növbəsində bu sınaqlardan istifadə edərək öz biliklərini yoxlaya bilərlər. Bu sınaqlar həmçinin şagirdlərin biliklərinin imtahan edilərək qiymətləndirməsi üçün də istifadə edilə bilər.",
    "moduleJournalInfo": "Müəllimlər şagirdlərin dərslərdə iştirakı ilə bağlı mütəmadi qeydlər apararaq, onların davamiyyətini və qiymətləndirməsini elektron formada həyata keçirə bilər. Müəllimlər bu dəyərləndirmələrin nəticəsində həm şagirlərin davamiyyətinə nəzarət edə biləcək, həm də onların qiymət ortalamasını görə biləcəklər.",
    "moduleLibraryInfo": "Bu moduldan həm şagirdlər, həm də məktəb tərəfindən təyin olunmuş kitabxanaçı kadr istifadə edə bilər. Belə ki, kitabxanaçı modulun köməkliyi ilə tabeliyində olan kitabların qeydiyyatını apararaq onlara nəzarət edə bilər. Şagirdlər isə kitabların siyahısı ilə buradan tanış olaraq, oxumaq istədiyi kitabı seçə bilər.Bu modulda həmçinin şagirdlər kitabxanaçı kadra onlayn formada müraciət edə bilər.",
    "moduleScheduleInfo": "Məktəb rəhbərliyi dərslərin bölgüsünü siniflər üzrə bölgü etdiktdən sonra şagirdlər və müəllimlər öz cədvəlləri ilə tanış olacaqlar. Belə ki, müəllimlər həftənin günləri üzrə dərs deyəcəyi sinifləri və həmin dərsin saatlarını buradan izləyəcəklər. Həmçinin şagirlər də dərs cədvəllərinə buradan baxa biləcəklər.",
    "error404": "404 TAPILMADI",
    "pgNotFound": "Axtardığınız səhifə tapılmadı.",
    "mainPage": "ANA SƏHİFƏ",
    //validations
    "lenMinFive": "Şifrə minimum 5 xanadan ibarət olmalıdır!",
    "enterPassword": "Şifrə daxil edin!",
    "enterYourPassword": "Şifrəni daxil edin!",
    "enterRepeat": "Şifrəni təkrar daxil edin!",
    "nonEqualPassword": "Şifrələr bir-birini təkrarlamır!",
    "enterUsername": "İstifadəçi adını daxil edin!",
    "enterName": "Adı daxil edin!",
    "enterSurname": "Soyadı daxil edin!",
    "enterFatherName": "Ata adını daxil edin!",
    "enterCompanyName": "Qurum adı daxil edin!",
    "enterPin": "Fin kodu daxil edin!",
    "pinLenMinSeven": "Fin kod 7 xanadan ibarət olmalıdır!",
    "enterPhone": "Telefon nömrəsi daxil edin!",
    "phoneLenMinSeven": "Telefon nömrəsi 7 xanadan ibarət olmalıdır!",
    "enterEmail": "Elektron ünvan daxil edin!",
    "incorrectEmailContent": "Elektron ünvanın tərkibi düzgün deyil",
    "enterFormName": "Anketin adını daxil edin!",
    "enterLabelText": "Label komponenti üçün mətn daxil edilməsi mütləqdir.",
    "enterServiceLink": "Servis linkini daxil edin!",
    "enterTabName": "Sütun adı daxil edin!",
    "enterSrGroupName": "Servis qrupunun adını daxil edin!",
    "enterSrName": "Servisin adını daxil edin!"
}
export default translationAz;