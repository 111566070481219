export const USER = {
    ME: "ME",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT"
};

export const COMMON = {
    SETSTATE: "SETSTATE",
    LOADING: "LOADING",
    RIGHTBAR: "RIGHTBAR",
    MODAL: "MODAL"
};