// import './assets/css/animate.min.css';
// import './assets/css/paper.css';
import './assets/css/tailwind.css';
// import './assets/css/bootstrap.min.css';
import './assets/css/theme/bootstrap.css';
// import './assets/css/style.css';
import './assets/css/theme/style.css';
import './assets/css/theme/responsive.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "./redux/store";

import App from "./containers/App";
import reportWebVitals from './reportWebVitals';

if (document.getElementById("root")) {
  ReactDOM.render(
    <Provider store={initStore}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
