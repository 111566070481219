import React, { Component } from 'react'
import { connect } from "react-redux";
import * as Common from "../../redux/actions/Common";
import * as userActions from "../../redux/actions/User";
import { withRouter } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { t } from '../../utils/translation/i18n';
import * as Icons from "react-icons/fi";

class Header extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        console.log(this.props);
    }

    handleLogout = () => {
        this.props.logout();
    };

    render() {
        const pages = [
            {
                url: "library",
                label: t("library")
            },
            {
                url: "examination",
                label: t("examinations")
            },
            {
                url: "contactUs",
                label: t("contactUs")
            }

        ];

        return (
            <header className={`header_section ${this.props.bgColor} ${this.props.mainCSS}`}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg custom_nav-container ">
                        <a className="navbar-brand" href={`/${localStorage.eSchoolLang}/`}>
                            <img src={require('../../assets/img/logo.png').default} alt=""></img>
                            <span>
                                School
                            </span>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                                <ul className="navbar-nav ">
                                    {pages.map((page, i) => {
                                        return <li className="nav-item " key={i}>
                                            <a className={`nav-link ${this.props.textColor}`} href={`/${localStorage.eSchoolLang}/${page.url}`}>{page.label} </a>
                                        </li>
                                    })}
                                </ul>
                                <form className="form-inline my-2 my-lg-0 ml-0 ml-lg-4 mb-3 mb-lg-0">
                                    <div className="btn-group">
                                        <Dropdown autoClose="outside" className="d-inline">
                                            <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-outside" className="flx-row tw-items-center tw-w-full px-3 tw-text-center tw-cursor-pointer tw-border-0 tw-shadow-none" bsPrefix="p-0">
                                                {t("languageChoise")}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="p-0">
                                                <div className="list-group text-nowrap">
                                                    <a href={`/az/${this.props.match.params.page ? this.props.match.params.page : ''}`} className={`list-group-item list-group-item-action py-2 small tw-cursor-pointer ${localStorage.eSchoolLang === "az" && 'tw-text-blue-500'}`}>Azərbaycan dili</a>
                                                    <a href={`/en/${this.props.match.params.page ? this.props.match.params.page : ''}`} className={`list-group-item list-group-item-action py-2 small tw-cursor-pointer ${localStorage.eSchoolLang === "en" && 'tw-text-blue-500'}`}>İngilis dili (English)</a>
                                                    <a href={`/ru/${this.props.match.params.page ? this.props.match.params.page : ''}`} className={`list-group-item list-group-item-action py-2 small tw-cursor-pointer ${localStorage.eSchoolLang === "ru" && 'tw-text-blue-500'}`}>Rus dili (Русский)</a>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        )
    }
}
export default withRouter(
    connect(({ ...state }) => ({ state }), {
        ...Common,
        ...userActions
    })(Header)
);