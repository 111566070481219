import React, { Component } from 'react'
import { t } from '../../utils/translation/i18n';

export default class Schedule extends Component {
    render() {
        return (
            <div className="common_style">
                <section className="determine_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="determine_detail-box">
                                    <h3>
                                        {t("moduleSchedule")}
                                    </h3>
                                    <p>{t("moduleScheduleInfo")}</p>
                                    <div className={`${this.props.moreInfo && 'tw-hidden'}`}>
                                        <a href={`/${localStorage.eSchoolLang}/schedule`} className="call_to-btn btn_white-border">
                                            {t("readMore")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="determine_img-container">
                                    <img src={require('../../assets/img/determine.png').default} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
